import React from "react"

import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger"
import Tooltip from "react-bootstrap/lib/Tooltip"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

import { DATA } from "../components/data"

const InfoIconButton = (props) => (
  <button
    className="info-icon"
    onMouseOut={props.onMouseOut}
    onMouseOver={props.onMouseOver}
    onFocus={() => {}}
    onBlur={() => {}}
  >
    <svg
      id="info-icon"
      data-name="Component 8 – 5"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="29"
      viewBox="0 0 30 29"
    >
      <g id="Path_7" data-name="Path 7" fill="#d8ba47">
        <path
          d="M 15 28.5 C 12.53271961212158 28.5 10.20596981048584 27.91206932067871 7.88677978515625 26.7026195526123 C 5.727590084075928 25.58064079284668 3.892950057983398 23.8724193572998 2.58053994178772 21.76227951049805 C 1.21943998336792 19.57386016845703 0.5 17.0626106262207 0.5 14.5 C 0.5 10.76368045806885 2.006119966506958 7.250080108642578 4.740910053253174 4.606450080871582 C 7.480299949645996 1.958359956741333 11.12372016906738 0.5 15 0.5 C 18.87627983093262 0.5 22.51969909667969 1.958359956741333 25.25909042358398 4.606450080871582 C 27.9938793182373 7.250080108642578 29.5 10.76368045806885 29.5 14.5 C 29.5 18.23632049560547 27.9938793182373 21.74991989135742 25.25909042358398 24.39355087280273 C 22.51969909667969 27.04163932800293 18.87627983093262 28.5 15 28.5 Z"
          stroke="none"
        />
        <path
          d="M 15 1 C 11.25408935546875 1 7.734090805053711 2.40846061706543 5.088420867919922 4.965940475463867 C 2.451959609985352 7.514520645141602 1 10.90044021606445 1 14.5 C 1 16.96918106079102 1.693359375 19.38912963867188 3.005119323730469 21.49821090698242 C 4.270170211791992 23.53219985961914 6.0377197265625 25.17831993103027 8.117979049682617 26.25929069519043 C 10.36404991149902 27.43061065673828 12.61517906188965 28 15 28 C 18.74591064453125 28 22.26591110229492 26.59153938293457 24.91157913208008 24.03405952453613 C 27.54804039001465 21.4854793548584 29 18.09955978393555 29 14.5 C 29 10.90044021606445 27.54804039001465 7.514520645141602 24.91157913208008 4.965940475463867 C 22.26591110229492 2.40846061706543 18.74591064453125 1 15 1 M 15 0 C 23.28426933288574 0 30 6.491870880126953 30 14.5 C 30 22.50812911987305 23.28426933288574 29 15 29 C 12.43188095092773 29 10.01449966430664 28.37612915039063 7.655580520629883 27.14595985412598 C 3.197359085083008 24.82933044433594 0 20.02560997009277 0 14.5 C 0 6.491870880126953 6.715730667114258 0 15 0 Z"
          stroke="none"
          fill="#707070"
        />
      </g>
      <text
        id="i"
        transform="translate(13 1)"
        fill="#707070"
        fontSize="20"
        fontFamily="SegoeUI, Segoe UI"
      >
        <tspan x="0" y="22">
          i
        </tspan>
      </text>
    </svg>
  </button>
)

const PricingPage = () => {
  const { pricing } = DATA

  const tooltipPosition =
    typeof window !== `undefined`
      ? window.innerWidth <= 760
        ? "top"
        : "right"
      : "top"

  return (
    <Layout>
      <SEO
        title={`Cennik`}
        description={`Cennik dla Balicka Clinic - klinika medycyny estetycznej w Jarosławiu. W ofercie zabiegi takie jak ${pricing.items
          .map((item) => item.title.toLowerCase())
          .join(", ")}`}
      />

      <div className="pricing">
        <div className="container">
          <h1 className="offer-item-title margin-top-30">Cennik</h1>
          <p className="offer-item-title margin-top-10">Zabiegi iniekcyjne wykonywane przez dr Luizę Balicką są o 100 zł droższe</p>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              {pricing.items.map((item, index) => {
                return (
                  <React.Fragment key={`${item.title}-${index}`}>
                    <div className={`panel ${index === 0 && `margin-top-10`}`}>
                      <div className="panel-header">
                        <p className="pricing-item-title">
                          {item.title}

                          {item.description && (
                            <OverlayTrigger
                              placement={tooltipPosition}
                              overlay={
                                <Tooltip
                                  id={item.description}
                                  className="pricing-tooltip"
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  ></span>
                                </Tooltip>
                              }
                            >
                              <InfoIconButton />
                            </OverlayTrigger>
                          )}
                        </p>
                      </div>

                      <table className="table table-responsive pricing-position-table">
                        <colgroup>
                          <col width="50%"></col>
                          <col width="50%"></col>
                        </colgroup>
                        <tbody>
                          {item.positions.map((position, _index) => {
                            return (
                              <tr key={`${position.name}-${_index}`}>
                                <td className="pricing-position-name">
                                  {position.name}
                                </td>
                                <td className="pricing-position-price">
                                  {position.price}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PricingPage
